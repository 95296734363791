<template>
  <div class="form-office">
    <Modal
      modal-class="slide-up modal_form"
      ref="modal_create_entry"
      size="lg"
      :on-hide="clearEntryToSave"
      :modal-header-title="tittle"
      @click="confirmClose"
    >
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @change="onchangeForm" v-on:submit.prevent="passes(saveEntry)">
            <div class="modal-body row">
              <div class="col-md-12"></div>
              <div class="col-md-6">
                <AppSelect
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.office_name')"
                  name="office_id"
                  :options-data="options_offices"
                  v-model="entryToSave.office_id"
                />
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-9">
                <AppInput
                  rules="required|max:191"
                  input-style="normal"
                  :label="$t('offices.plan_name')"
                  type="string"
                  name="plan_name"
                  v-model="entryToSave.name"
                />
              </div>
              <div class="col-md-3"></div>

              <div class="col-md-3">
                <AppInput
                  input-style="normal"
                  :label="$t('common.price_plan')"
                  type="bigint"
                  name="price"
                  rules="required|min_value:0|max_value:4294967295"
                  v-model="entryToSave.price"
                />
              </div>
              <div class="col-md-9"></div>

              <div class="col-md-9">
                <AppText
                  type="text"
                  name="description"
                  input-style="normal"
                  :label="$t('offices.plan_description')"
                  aria-valuemax="10000"
                  v-model="entryToSave.description"
                  rules="required|max:10000"/>
              </div>

              <div class="col-md-9">
                <div class="form-group">
                  <label class="m-b-5 input-label control-label p-0 p-r-5">
                    {{ $t('offices.plan_image') }}
                    <span class="color-active app-text-bold">＊</span>
                  </label>
                </div>

                <img
                  :height="officeImage.height + 'px'"
                  :width="officeImage.width + 'px'"
                  v-if="entryToSave.image_url != '' && entryToSave.image_url != null"
                  :src="entryToSave.image_url"
                />
                <div
                  v-if="entryToSave.image_url != ''"
                  class="btn-tool delete remove-image"
                  @click="removeImage()">
                </div>
                <input
                  ref="entryToSave_img"
                  accept="image/jpg,image/jpeg,image/png"
                  style="height:40px;"
                  class="form-control"
                  type="file"
                  name="image"
                  @change="uploadImage($event)"
                >

                <label class="error app-input-error-label-2"
                       v-if="entryToSave.image_url.length == 0 && submit == true">
                  こちらは必須項目です。
                </label>
              </div>

              <div class="col-md-12"></div>

              <div class="col-md-3">
                <AppSelect
                  rules="required"
                  input-style="normal"
                  :label="$t('offices.status_plan')"
                  name="status"
                  :options-data="status"
                  v-model="entryToSave.status"
                />
              </div>
            </div>

            <div class="modal-footer">
              <button
                @click="confirmClose"
                type="button"
                :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
              >
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="(!valid && validated ||
              (entryToSave.image_url.length == 0 && submit == true))" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ entryToSave.id ? $t('common.update_btn') : $t('common.create_btn') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>

    <ConfirmModal
      ref="modal_delete_entry"
      :modal-header-title="$t('common.confirm_cancel_title')"
      :confirm-handle="deleteEntrySubmit">
    </ConfirmModal>

    <OfficePlanTable
      ref="table"
      :table-name="tableName"
      :setting-columns="columns"
      :actions="actions"
      :filters.sync="filters"
      :search="search"
      :setting-apis="apis"
      @deletedEntry="onDeletedEntry"
    />
  </div>
</template>
<script>
  import OfficePlanTable from '@components/_common/List/OfficePlanTable';
  var assert = require("assert");

  export default {
    data() {
      const image_default= {
        height:300,
        width:400
      }
      const image_default_square= {
        height:550,
        width:550
      }
      return {
        submit: false,
        no_image: false,
        url: true,
        active: 0,
        tittle:"",
        tableName: this.$t('users.user list'),
        apis: {
          list: {
            endpoint: this.$consts.API.ADMIN.OFFICE_PLANS_LIST,
          },
          delete: {
            endpoint: this.$consts.API.ADMIN.OFFICE_PLANS_DELETE,
          }
        },
        search: {
          searchAll: false,
          reset: true,
        },
        options_offices: [],
        filters: {
          company_id_equal: {
            name: 'company_name',
            type: 'multi_like',
            label: this.$t('offices.company_name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: "col-lg-3 col-md-3 col-sm-6",
            options: [],
          },
          office_id_equal: {
            name: 'office_id',
            type: 'multi_like',
            label: this.$t('offices.office_name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: "col-lg-3 col-md-3 col-sm-6",
            options: [],
          },
          plan_name_like: {
            name: 'plan_name',
            type: 'multi_like',
            label: this.$t('offices.plan_name'),
            uiType: 'multi_select',
            ui_options: {
              closeOnSelect: true,
            },
            boundaryClass: "col-lg-3 col-md-3 col-sm-6",
            options: [],
          },
          status_equal: {
            name: 'status',
            type: 'equal',
            label: this.$t('offices.status_plan'),
            uiType: 'select',
            options:[
              {
                id: "",
                value: "",
              },
              {
                id: 0,
                name: this.$t('offices.stop_sale')
              },
              {
                id: 1,
                name: this.$t('offices.sale')
              },
              {
                id: "none",
                name: this.$t('common.no_select_all'),
              },
            ],
            boundaryClass: "col-lg-3 col-md-3 col-sm-6"
          },
        },
        actions: {
          name: "office_plans",
          editEntry: this.editEntry,
          deleteEntry: true,
          createEntry: this.createEntry,
        },
        entryToSave: {
          status: 1,
          image_url: "",
        },
        cloneSave:{},
        status: [
          {
            id: 0,
            name: this.$t('offices.stop_sale')
          },
          {
            id: 1,
            name: this.$t('offices.sale')
          },
        ],
        columns: [
          {name: 'company_name', label: this.$t('offices.company_name'),
            computedHtml: entry => {
              return entry.office.company_name
            },
          },
          {name: 'office_name', label: this.$t('offices.office_name'), class: 'min-w-180',
            computedHtml: entry => {
              return entry.office.office_name
            },},
          {name: 'name', label: this.$t('offices.plan_name')},
          {name: 'price', label: this.$t('furusato_product.price'), class: 'min-w-100'},
          {name: 'description', label: this.$t('offices.plan_description'), class: 'min-w-180'},
          {
            label: this.$t('offices.plan_image'),
            name: 'image_url',
            class: "offices-width-180",
            computedHtml: entry => {
              return "<img src='"+entry.image_url+"' style='width:80px'>"
            },
          },
          {
            name: 'status',
            label: this.$t('offices.status_plan'),
            class: "offices-width-180",
            computedHtml: entry => {
              if (entry.status) {
                return '<span class="alert alert-info">' + this.$t('offices.sale') + '</span>'
              } else {
                return '<span class="alert alert-disable">' + this.$t('offices.stop_sale') + '</span>'
              }
            },
          },
        ],
        image_default: _.cloneDeep(image_default),
        image_default_square: _.cloneDeep(image_default_square),
        image:{
          height:'',
          width:''
        },
        officeImage: _.cloneDeep(image_default),
      }
    },
    components: {OfficePlanTable},
    mounted() {
      this.getDataSelect();
      this.getOffices()
      this.$request.get(this.$consts.API.ADMIN.UI_HELPER_OFFICE_PLANS_LIST).then(res => {
        if (!res.hasErrors()) {
          this.filters.company_id_equal.options = _.cloneDeep(res.data.company);
          this.filters.plan_name_like.options = _.cloneDeep(res.data.name_plan);
          this.filters.office_id_equal.options = _.cloneDeep(res.data.offices);
        }
      })
    },
    methods: {
      getDataSelect() {
        this.$request.get(this.$consts.API.ADMIN.OFFICE_PLANS_LIST).then(res => {
          if(!res.hasErrors()) {
            this.filters = _.cloneDeep(this.filters)
          }
        })
      },
      async getOffices() {
        const res = await this.$request.get(this.$consts.API.ADMIN.UI_HELPER_OFFICE_PLANS_LIST, {});
        if (!res.hasErrors()) {
          let offices = res.data.offices;
          if (offices.length !== 0) {
            this.options_offices = offices;
          } else {
            this.options_offices = []
          }
        }
      },

      createEntry() {
        this.submit = false
        let idOffice = ""
        this.tittle = this.$t('offices.create_office_plans');
        if (typeof this.filters.office_id_equal.value == 'object') {
          if (this.filters.office_id_equal.value != null && this.filters.office_id_equal.value.length == 1) {
            idOffice = this.filters.office_id_equal.value[0]
          }
        } else {
          idOffice = this.filters.office_id_equal.value
        }
        this.entryToSave = {
          status: 1,
          image_url: "",
          office_id: idOffice
        };

        this.cloneSave = _.cloneDeep(this.entryToSave);
        this.$refs.entryToSave_img.value = null;
        this.$refs.form_create_entry.reset();
        this.$refs.modal_create_entry.show();
      },
      editEntry (entry) {
        this.tittle = this.$t('offices.edit_offices');
        this.entryToSave = _.cloneDeep(entry);
        this.cloneSave = _.cloneDeep(this.entryToSave);
        this.$refs.modal_create_entry.show();
        this.checkImageSize()
      },
      confirmClose() {
        this.$refs.modal_delete_entry.show();
      },
      onchangeForm() {
        this.active = 1;
      },
      clearEntryToSave() {
        this.no_image = false;
        this.$refs.entryToSave_img.value = null;
        this.$refs.form_create_entry.reset();
        this.$refs.table.getEntries();
      },
      deleteEntrySubmit()
      {
        this.$refs.table.getEntries();
        this.$refs.modal_delete_entry.hide();
        this.$refs.modal_create_entry.hide();
        this.active = 0;
      },
      async saveEntry() {
        this.submit = true;
        if (this.entryToSave.image_url.length == 0) {
          return;
        }
        this.active = 0;
        if (this.entryToSave.id) {
          const res = await this.$request.patch(this.$consts.API.ADMIN.OFFICES_PLAN_SAVE + '/' + this.entryToSave.id, this.entryToSave);

          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg update ok'))
            this.$refs.modal_create_entry.hide();
            this.$refs.table.getEntries();
          }
        } else {
          const res = await this.$request.post(this.$consts.API.ADMIN.OFFICES_PLAN_SAVE, this.entryToSave);
          if (res.hasErrors()) {
            this.$appNotice.error( res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          } else {
            this.$appNotice.success(this.$t('common.msg create ok'))
            this.$refs.modal_create_entry.hide();
            this.$refs.table.getEntries();
            let officesPlan = JSON.parse(localStorage.getItem('OFFICE_PLANS'));
            if (officesPlan.hasOwnProperty(res.data.data.office_id)) {
              officesPlan[res.data.data.office_id].push(res.data.data.id)
              localStorage.setItem('OFFICE_PLANS', JSON.stringify(officesPlan));
            }
          }
        }
      },
      async uploadImage(event) {
        const image = event.target.files[0];

        if (this.checkImage(image)) {
          this.$appNotice.error(this.$t('dialog.image_type'));
          this.$refs.entryToSave_img.value = null;
          return;
        }

        if ((image.size / 1048576) > 2) {
          this.$appNotice.error(this.$t('dialog.image_max', {size: '2MB'}));
          this.$refs.entryToSave_img.value = null;
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = e =>{
          this.uploadImage1(e.target.result)
          let img = new Image();
          img.onload = () => {
            this.image.width = img.width;
            this.image.height = img.height;
            this.changeSize(img.width, img.height)
          }
          img.src = e.target.result;
        };
      },
      async changeSize(width, height) {
        this.officeImage = _.cloneDeep(this.image_default)
        if (width === height) {
          this.officeImage = _.cloneDeep(this.image_default_square)
        }
      },

      async uploadImage1(file) {
        const res = await this.$request.post(this.$consts.API.ADMIN.UPLOAD_IMAGE_OFFICE_PLANS, {
          image: file,
          path: "office_plans/",
        });
        this.no_image = false;
        this.entryToSave.image_url = res.data.data;
      },

      removeImage() {
        this.entryToSave.image_url = '';
        this.$refs.entryToSave_img.value = null
      },
      onDeletedEntry() {
        this.getDataSelect();
      },
      checkImageSize() {
        this.checkSize(this.entryToSave.image_url)
      },
      checkSize(source) {
        let img = new Image();
        img.onload = () => {
          this.image.width = img.width;
          this.image.height = img.height;
          this.changeSize(img.width, img.height)
        }
        img.src = source;
      },
    }
  }
</script>
